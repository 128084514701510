<template>
  <div>
    <b-row>
      <b-col lg="12" sm="12">
        <b-card v-if="accounting" no-body class="card-statistics">
          <b-card-body class="statistics-body">
            <b-row>
              <b-col xl="2" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ accounting.totalRevenue }}€
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ $t("Pages.Channel.Title.total_income") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="2" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ accounting.totalUserRevenue }}€
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ $t("Pages.Channel.Title.user_share") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="2" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ accounting.totalAdsenseRevenue }}€
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ $t("Pages.Channel.Title.admin_share") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="2" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4
                      class="font-weight-bolder mb-0 text-success"
                      v-if="
                        accounting.totalUserRevenue >=
                        accounting.totalPaymentAdsense
                      "
                    >
                      {{ accounting.totalPaymentAdsense }}€
                    </h4>
                    <h4 class="font-weight-bolder mb-0 text-danger" v-else>
                      {{ accounting.totalPaymentAdsense }}€
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ $t("Pages.Channel.Title.user_deposit") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="2" md="3" class="mb-md-0 mb-2">
                <label>{{ $t("Pages.Channel.Label.export_excel") }}</label
                ><br />
                <button
                  class="btn btn-cart btn-success"
                  @click="createAndDownloadExcelFile"
                >
                  {{ $t("Pages.Channel.Button.export_excel") }}
                </button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" sm="12">
        <b-card v-if="tableData" no-body class="card-company-table">
          <b-overlay
            :show="!showTable"
            spinner-variant="primary"
            variant="transparent"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-table
              :items="tableData"
              responsive
              :fields="fields"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              :empty-text="$t('Pages.Channel.Table.no_match_record')"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
              id="adsense"
            >
              <template #cell(name)="data">
                <a
                  class="d-flex align-items-center"
                  @click="showReceiptPayment(data.item)"
                >
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.avatar"
                      alt="avatar img"
                  /></b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      {{ data.item.nickname }}
                    </div>
                    <div class="font-small-2 text-muted">
                      {{ data.item.country }}
                    </div>
                  </div>
                </a>
              </template>

              <template #cell(financial_month)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.months
                  }}</span>
                </div>
              </template>

              <template #cell(revenue)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.count
                  }}</span>
                </div>
              </template>

              <template #cell(revenue_adsense)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.taxation
                  }}</span>
                </div>
              </template>

              <template #cell(revenue_user)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.income
                  }}</span>
                </div>
              </template>

              <template #cell(exchange_range)="data">
                <div class="d-flex flex-column" v-if="data.item.status == 1">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.exchange_range
                  }}</span>
                </div>
              </template>

              <template #cell(deposit_toman)="data">
                <div class="d-flex flex-column" v-if="data.item.status == 1">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.price_toman
                  }}</span>
                </div>
              </template>

              <template #cell(payment_status)="data">
                <div class="d-flex flex-column">
                  <b-badge
                    v-if="data.item.status === null"
                    variant="light-danger"
                  >
                    {{ $t("Pages.Channel.Table.status_unpaid") }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 0"
                    variant="light-info"
                  >
                    {{ $t("Pages.Channel.Table.status_paying") }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 1"
                    variant="light-warning"
                  >
                    {{ $t("Pages.Channel.Table.status_checking") }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 2"
                    variant="light-success"
                  >
                    {{ $t("Pages.Channel.Table.status_paid") }}
                  </b-badge>
                </div>
              </template>

              <template #cell(payment_receipt)="data">
                <div class="d-flex flex-column">
                  <feather-icon
                    icon="EyeIcon"
                    size="16"
                    class="mx-1 cursor-pointer"
                    v-if="data.item.status == 2"
                    @click="showReceiptPayment(data.item)"
                  />
                </div>
              </template>
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-col
      cols="12"
      sm="12"
      v-if="pages > 1"
      class="d-flex align-items-center justify-content-center justify-content-sm-end"
    >
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="pages"
        use-router
        class="mb-0"
      />
    </b-col>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BAlert,
  BBadge,
  BButton,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BDropdownItem,
  BInputGroupAppend,
  BFormInput,
  BPaginationNav,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import XLSX from "xlsx";

export default {
  components: {
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BImg,
    BLink,
    BButton,
    BAlert,
    BBadge,
    BTooltip,
    BCardHeader,
    BOverlay,
    BDropdownItem,
    BInputGroupAppend,
    BFormInput,
    BPaginationNav,
  },
  data() {
    return {
      pages: 1,
      tableData: [],
      fields: [
        { key: "name", label: this.$t("Pages.Channel.Table.channel_name") },
        {
          key: "financial_month",
          label: this.$t("Pages.Channel.Table.financial_month"),
        },
        {
          key: "revenue",
          label: `${this.$t("Pages.Channel.Table.total_income")} (€)`,
        },
        {
          key: "revenue_adsense",
          label: `${this.$t("Pages.Channel.Table.admin_share")} (€)`,
        },
        {
          key: "revenue_user",
          label: `${this.$t("Pages.Channel.Table.user_share")} (€)`,
        },
        {
          key: "exchange_range",
          label: `${this.$t("Pages.Channel.Table.exchange_range")}`,
        },
        {
          key: "deposit_toman",
          label: `${this.$t("Pages.Channel.Table.deposit_toman")}`,
        },
        {
          key: "payment_status",
          label: this.$t("Pages.Channel.Table.payment_status"),
        },
        {
          key: "payment_receipt",
          label: this.$t("Pages.Channel.Table.payment_receipt"),
        },
      ],
      accounting: {
        totalRevenue: "0",
        totalAdsenseRevenue: "0",
        totalUserRevenue: "0",
        totalPaymentAdsense: "0",
        showTable: false,
      },
      filters: {
        q: "",
      },
      json_export: [],
    };
  },
  setup() {
    return {
      sortBy: ref("id"),
      isSortDirDesc: ref(true),
    };
  },
  created() {
    this.getList();
    this.getAccounting();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getNewList();
        this.getAccounting();
      }
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1
        ? `?id=${this.$route.query.id}`
        : `?id=${this.$route.query.id}&page=${pageNum}`;
    },
    getAccounting() {
      console.log(this.$route.query.id);
      this.$http
        .get(
          `/api/accounting/channels/revenue?id=${this.$route.query.channel_id}`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.accounting.totalRevenue = response.data.revenue;
          this.accounting.totalAdsenseRevenue = response.data.revenueAdsense;
          this.accounting.totalUserRevenue = response.data.revenueUser;
          this.accounting.totalPaymentAdsense = response.data.paymentAdsense;
        });
    },
    getList() {
      let page =
        typeof this.$route.query.page !== "undefined"
          ? this.$route.query.page
          : 1;
      this.$http
        .get(
          `/api/accounting/channels/details?id=${this.$route.query.channel_id}&page=${page}`
        )
        .then((response) => {
          this.showTable = true;
          this.tableData = response.data.data;
          this.pages = response.data.last_page;
        });
    },
    getNewList() {
      let page =
        typeof this.$route.query.page !== "undefined"
          ? this.$route.query.page
          : 1;
      this.$http
        .get(
          `/api/accounting/channels/details?id=${this.$route.query.channel_id}&page=${page}`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.tableData = response.data.data;
          this.pages = response.data.last_page;
        });
    },
    createAndDownloadExcelFile() {
      let array = [];
      this.tableData.map((item) => {
        if (item.transaction_id) {
          let obg = {};
          obg[this.$t(this.fields[0].label)] = item.nickname;
          obg[this.$t(this.fields[1].label)] = item.months;
          obg[this.$t(this.fields[2].label)] = item.count;
          obg[this.$t(this.fields[3].label)] = item.taxation;
          obg[this.$t(this.fields[4].label)] = item.income;
          obg[this.$t(this.fields[5].label)] = item.exchange_range;
          obg[this.$t(this.fields[6].label)] = item.price_toman;
          if (data.item.status === null) {
            obg[this.$t(this.fields[7].label)] = this.$t(
              "Pages.Channel.Table.status_unpaid"
            );
          } else if (data.item.status == 0) {
            obg[this.$t(this.fields[7].label)] = this.$t(
              "Pages.Channel.Table.status_paying"
            );
          } else if (data.item.status == 1) {
            obg[this.$t(this.fields[7].label)] = this.$t(
              "Pages.Channel.Table.status_checking"
            );
          } else if (data.item.status == 2) {
            obg[this.$t(this.fields[7].label)] = this.$t(
              "Pages.Channel.Table.status_paid"
            );
          }
          array.push(obg);
        }
      });
      var dataWS = XLSX.utils.json_to_sheet(array);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "MasterTube");
      XLSX.writeFile(wb, "revenue_mastertube.xlsx");
    },
    showReceiptPayment(data) {
      if (data.transaction_id) {
        this.$http
          .get(`/api/accounting/channels/details/PDF`, {
            params: {
              uuid: data.uuid,
            },
          })
          .then((response) => {
            window.open(response.data);
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
